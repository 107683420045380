<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0" v-if="isLoadingWorkspacesList">
        <div  class="mb-4 mt-4">
            <div class="text-center flex-center h-100">
              <div class="spinner-border text-primary" role="status">
              </div>
              <br />
              Connexion
            </div>
          </div>
      </b-card> 
      <b-card class="mb-0" v-else>
        <b-link class="brand-logo">
          <orisis-logo-full />
        </b-link>

        <b-card-title class="mb-1">
          Choisissez votre espace de travail  !
        </b-card-title>
        <b-card-text class="mb-2">
          Votre nouveau logiciel de <span>gestion d'entreprise</span> à haute performance.
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
          rules="required"
        >
          <v-select :state='invalid.length > 0 ? false : null' :options="userInfo.workspaces" label="socialReason" v-model="userWorkspace" :reduce='(type) => type.id'>
            <template #option='{ socialReason }'>
              {{ socialReason }}
            </template>
            <template #selected='{ socialReason }'>
              {{ socialReason }}
            </template>
            <template v-slot:no-options="">
              Aucun r&eacute;sultat trouv&eacute;
            </template>
          </v-select>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Connexion
            </b-button>
            <b-card-text class="text-center mt-2">
              <span>Logiciel soumis à l'acceptation des </span>
              <a href="https://www.orisis.fr/mentions-legales" target="new">
                <span>CGU</span>
              </a>
            </b-card-text>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { ValidationObserver, configure, localize } from 'vee-validate';
import { required} from "@validations";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
configure({
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')


import {
  BButton, BForm, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";

export default {
  components: {
    BButton,
    BForm,
    BCard,
    BCardTitle,
    BLink,
    OrisisLogoFull,
    BCardText,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userWorkspace: '',
      // validation rules
      required,
    }
  },
  created(){
    if (this.userInfo.workspaces.find(elem => elem.isDefault == true)) {
      this.userWorkspace = this.userInfo.workspaces.find(elem => elem.isDefault == true).id
    }
  },
  computed: {
    ...mapGetters(['workspacesList', 'isLoadingWorkspacesList', 'userInfo']),
  },
  methods: {
    ...mapActions(["fetchWorkspacesList"]),
      validationForm() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.$store.dispatch('setWorkspaceSelected', {workspaceIdSelected: this.userWorkspace}).then(() => {
            this.$router.push("/");
          })
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
